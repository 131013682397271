// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {GES_FINANCIERS} from "./configs/ges-financiers.const";

export const environment = {
  stage: 'dev',
  version: 'Ambiente de Desarrollo | v2.0.50',
  production: false,
  contactEmailEmma: 'emma.cmv@ucchristus.cl',
  bucket: 'cmv-recibos-pagos-pri-dev-use1',
  cmvBaseApi: 'https://api.cmv.dev.ucchristus.io',
  uccBaseApi: 'https://apigw.ucchristus.cl/agendaambulatoria-pre',
  photos: 'https://assets.ucchristus.cl/fotosProfesionales/dev',
  gesFinanciers: GES_FINANCIERS.dev,
  cancelAppointmentReasonId: "9401be33-3477-4ea5-89db-acf00116943f",
  cognitoUserPool: {
    userPoolId: 'us-east-1_FhbW4ceo6',
    userPoolClientId: '6tjdddngshrd6dqd2884nojj7v'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
